import React from 'react';
import { graphql } from 'gatsby';
import GatsbyLink from '../components/GatsbyLink'
import Layout from '../components/Layout';
import Previewable from '../components/Previewable';
import SEO from '../components/SEO';
import { PageHeader } from '../components/PageHeader';
import { RelatedPosts } from '../components/Related/RelatedPosts';
import RenderContent from '../components/RenderContent';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { Image } from '../components/Image';
import './post.scss';
import { decodeEntities, stripHtmlTags } from '../utils/helpers';
import { dateI18n } from '@wordpress/date';

const BlogPost = ( props ) => {
  const { data, location } = props;
  const { wordpressPost: post, site, siteSettings, wordpressWpSettings } = data;
  if (!post) return null;
  const { title, content, excerpt, featured_image_url, yoast, categories, date, author } = post;
  const { blogSlug, date_format } = wordpressWpSettings;
  const dateFormatted = date && date_format && dateI18n(date_format,date)
  return (
    <Layout location={location}>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(wordpressWpSettings.title)}`}
        desc={excerpt ? stripHtmlTags(excerpt) : null}
        yoast={yoast}
        banner={featured_image_url && featured_image_url.source_url && featured_image_url.source_url}
        location={location}
      />
      <section className="single-post">
        {featured_image_url && featured_image_url.source_url && <div className="feature-image"><Image src={featured_image_url}/></div>}
        <div className="head">
          <div className="wrap">
            <Breadcrumbs location={location} />
            {categories && categories.length && (
              <ul className="taglist">
                {Object.keys(categories).length > 0 &&
                  categories?.map((category) => {
                    if(category.name === "All") return null
                    return (
                      <li key={`${category.slug}cat`}>
                        {category.slug && (
                          <GatsbyLink to={category.path}>
                            {category.name}
                          </GatsbyLink>
                        )}
                      </li>
                    )
                  })
                }
              </ul>
            )}
            <div className="post-title">
              <h1 dangerouslySetInnerHTML={{__html: title}} />
            </div>
          </div>
        </div>
        <div className="outer">
          <div className="wrap">
            <div className="inner">
              <div className="wrap">
                <RenderContent content={content} />
              </div>
              {siteSettings.options.showAuthor &&
              <div className="meta">
                <h4>Posted by</h4>
                <div className="author">
                  {author && author.avatar_urls && author.avatar_urls.wordpress_48 && <div className="avatar"><Image src={author.avatar_urls.wordpress_48}/></div>}
                  <div>
                    {author && <GatsbyLink to={author.path} className="author">{author.name}</GatsbyLink>}
                    {date && <span className="date">{date}</span>}
                  </div>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      </section>
      <RelatedPosts />
    </Layout>
  )
}

export default Previewable(BlogPost, 'post');
//export default BlogPost;
export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date
    title
  }
  query BlogPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      date_format
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        showAuthor
      }
    },
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      path
      content
      excerpt
      wordpress_id
      date
      featured_image_url {
        source_url
      }
      categories {
        name
        slug
        path
      }
      author {
        name
        slug
        path
        avatar_urls {
          wordpress_48
        }
      },
      yoast {
        metaTitle: title,
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
    }
  }
`
